import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const AGENDA_ENDPOINTS = {
  create_event: `${BASE_URL}api/events/create_event`,
  delete_event: `${BASE_URL}api/events/delete_event`,
  edit_event: `${BASE_URL}api/events/edit_event`,
  list_events: `${BASE_URL}api/events/list_events`
};

class Agenda {
  /* 
  NOTE:
    Only change is attachments should be an array with file names and ids.

    attachments: [{name: "file_name", id: "file_id"}]

  */
  async create_event({
    organization,
    project,
    participants,
    title,
    subject,
    attachments,
    date,
    time,
    users,
    levels,
    roles,
  }) {
    var { data, res } = await request({
      url: AGENDA_ENDPOINTS.create_event,
      method: "PUT",
      skipRedirect: true,
      body: {
        organization,
        project,
        participants,
        title,
        subject,
        attachments,
        date,
        time,
        users,
        levels,
        roles,
      },
    });
    return { data, res };
  }

  async delete_event({ organization, project, title, _id }) {
    var { data, res } = await request({
      url: AGENDA_ENDPOINTS.delete_event,
      method: "DELETE",
      skipRedirect: true,
      body: { organization, project, title, _id },
    });
    return { data, res };
  }

    /* 
  NOTE:
    Only change is attachments should be an array with file names and ids.

    update_data.attachments: [{name: "file_name", id: "file_id"}]
    
  */
  async edit_event({
    organization,
    project,
    _id,
    title,
    users,
    levels,
    roles,
    update_data,
  }) {
    console.log("Started js");
    var { data, res } = await request({
      url: AGENDA_ENDPOINTS.edit_event,
      method: "POST",
      skipRedirect: true,
      body: {
        organization,
        project,
        _id,
        title,
        users,
        levels,
        roles,
        update_data,
      },
    });
    return { data, res };
  }

  async list_events(homepage = false) {
    var { data, res } = await request({
      url: `${AGENDA_ENDPOINTS.list_events}?homepage=${homepage}`,
      method: "GET",
      skipRedirect: true,
    });
    return { data, res };
  }

}

export const AgendaService = new Agenda();
