import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const PROJECTS_ENDPOINTS = {
  list_templates: `${BASE_URL}api/projects/list_templates`,
  get_template_details: `${BASE_URL}api/projects/get_template_details`,
  save_template: `${BASE_URL}api/projects/save_template`,
  edit_template: `${BASE_URL}api/projects/edit_template`,
  delete_template: `${BASE_URL}api/projects/delete_template`,
  list_projects: `${BASE_URL}api/projects/list_projects`,
  save_project: `${BASE_URL}api/projects/save_project`,
  rename_project: `${BASE_URL}api/projects/rename_project`,
  delete_project: `${BASE_URL}api/projects/delete_project`,
  update_project_active: `${BASE_URL}api/projects/update_project_active`,
  list_avaliable_members: `${BASE_URL}api/projects/list_avaliable_members`,
  save_phases_categories_order: `${BASE_URL}api/projects/save_phases_categories_order`,
  get_phases_categories_order: `${BASE_URL}api/projects/get_phases_categories_order`,
};

class Projects {
  async save_phases_categories_order({ organization, project, structure }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.save_phases_categories_order,
      method: "PUT",
      skipRedirect: true,
      body: { organization, project, structure },
    });
    return {
      data,
      res,
    };
  }

  async get_phases_categories_order({ organization, project }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.get_phases_categories_order,
      method: "POST",
      skipRedirect: true,
      body: { organization, project },
    });
    return {
      data,
      res,
    };
  }

  async list_projects({ organization_name }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.list_projects,
      method: "POST",
      skipRedirect: true,
      body: { organization_name },
    });
    return {
      data,
      res,
    };
  }

  async list_avaliable_members({ organization }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.list_avaliable_members,
      method: "POST",
      skipRedirect: true,
      body: { organization },
    });
    return {
      data,
      res,
    };
  }

  async list_templates() {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.list_templates,
      method: "GET",
      skipRedirect: true,
    });
    return {
      data,
      res,
    };
  }

  async get_template_details({ name }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.get_template_details,
      method: "POST",
      skipRedirect: true,
      body: { name },
    });
    return {
      data,
      res,
    };
  }

  async save_template({ name, structure }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.save_template,
      method: "PUT",
      skipRedirect: true,
      body: { name, structure },
    });
    return {
      data,
      res,
    };
  }

  async edit_template({ name, structure }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.edit_template,
      method: "POST",
      skipRedirect: true,
      body: { name, structure },
    });
    return {
      data,
      res,
    };
  }

  async delete_template({ name }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.delete_template,
      method: "POST",
      skipRedirect: true,
      body: { name },
    });
    return {
      data,
      res,
    };
  }

  async save_project({ organization, name, team }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.save_project,
      method: "PUT",
      skipRedirect: true,
      body: { organization, name, team },
    });
    return {
      data,
      res,
    };
  }

  async rename_project({ project_name, new_project_name }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.rename_project,
      method: "POST",
      skipRedirect: true,
      body: { project_name, new_project_name },
    });
    return {
      data,
      res,
    };
  }

  async delete_project({ project_name }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.delete_project,
      method: "DELETE",
      skipRedirect: true,
      body: { project_name },
    });
    return {
      data,
      res,
    };
  }

  async update_project_active({ project_name }) {
    var { data, res } = await request({
      url: PROJECTS_ENDPOINTS.update_project_active,
      method: "POST",
      skipRedirect: true,
      body: { project_name },
    });
    return {
      data,
      res,
    };
  }
}

export const ProjectsService = new Projects();
