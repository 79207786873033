<template>
  <CModal
    :show="showConfirmDeletion"
    @hide="closeConfirmDeletion"
    :top="true"
    :draggable="false"
  >
    <template v-slot:header>
      <h3>{{ $t("Confirm Deletion") }}</h3>
      <CButton @click="closeConfirmDeletion">
        <i class="fa fa-times"></i>
      </CButton>
    </template>
    <template v-slot:footer>
      <CButton @click="closeConfirmDeletion" class="link">{{
        $t("Cancel")
      }}</CButton>
      <CButton
        data-testid="confirmDeletionButton"
        @click="confirmDeletion"
        color="danger"
        class="modal-confirm"
      >
        {{ $t("Delete") }}
      </CButton>
    </template>
    <p>{{ $t("Are you sure you want to delete this event") }}?</p>
  </CModal>
</template>

<script>
export default {
  props: {
    showConfirmDeletion: Boolean,
  },
  methods: {
    closeConfirmDeletion() {
      this.$emit("close");
    },
    confirmDeletion() {
      this.$emit("confirm");
      this.closeConfirmDeletion();
    },
  },
  
};
</script>
