<template>
  <div class="AgendaPage">
    <div
      class="d-flex justify-content-between add-container mt-1 mb-3 align-center"
    >
      <h1 :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'">
        {{ $t("Agenda") }}
      </h1>

      <CButton
        v-if="doesHaveRight('createEvents')"
        data-testid="addEventButton"
        class="text-white btn-add"
        @click="openEventModal"
        :disabled="isLoading"
        >{{ $t("Add Event") }}</CButton
      >
    </div>
    <ContentLoader
      v-if="isLoading"
      :speed="2"
      primaryColor="rgb(230, 230, 230)"
      secondaryColor="rgb(240, 240, 240)"
      class="p-2"
    ></ContentLoader>
    <div v-else class="">
      <FullCalendar
        style=""
        class="agenda_container"
        ref="fullCalendar"
        v-bind:options="options"
        :eventClick="handleEventClick"
      >
        <template v-slot:eventContent="arg">
          <div class="d-flex position-relative h-100">
            <div
              :style="
                arg.event.extendedProps.type == 'Training' &&
                Object.keys(arg.event.extendedProps).includes('trainingColor')
                  ? `border-color:${arg.event.extendedProps.trainingColor}!important; background:${arg.event.extendedProps.trainingColor}!important`
                  : 'border-color:#6e85b7 !important'
              "
              :class="
                arg.event.extendedProps.type === 'Training'
                  ? 'events_training'
                  : 'events_meeting'
              "
            ></div>

            <span
              class="event-title mt-1 pointer"
              @click="toggleDrawer(arg.event)"
            >
              {{ arg.event.extendedProps.type }} {{ arg.event.title }} :
              {{ arg.event.extendedProps.time }}</span
            >
            <i
              v-if="doesHaveRight('modifyEvents')"
              data-testid="editEventIcon"
              class="options blue fa fa-pencil ml-2 pointer"
              style="margin-top: 7px"
              @click="editEvent(arg.event)"
            ></i>
            <img
              v-if="doesHaveRight('deleteEvents')"
              class="img ml-2 mr-1 pointer options"
              style="margin-top: 5px"
              :src="require('@/assets/Images/cross.png')"
              alt="delete"
              @click="deleteEvent(arg.event)"
              data-testid="deleteEventIcon"
            />
            <span class="position-relative">
              <i
                data-testid="deleteEventIcon"
                class="fa fa-ellipsis-v ml-1 mr-1 pointer"
                style="margin-top: 6px"
                @click="openDropDown(arg.event)"
              ></i>
              <div
                v-if="showDropDown && selectedEventForDropdown === arg.event"
                class="options_event"
                ref="dropdown"
              >
                <i
                  v-if="doesHaveRight('modifyEvents')"
                  data-testid="editEventIcon"
                  class="blue fa fa-pencil pointer"
                  style="margin-top: 7px"
                  @click="editEvent(arg.event)"
                ></i>
                <img
                  v-if="doesHaveRight('deleteEvents')"
                  class="img mb-2 pointer"
                  style="margin-top: 7px"
                  :src="require('@/assets/Images/cross.png')"
                  alt="delete"
                  @click="deleteEvent(arg.event)"
                  data-testid="deleteEventIcon"
                />
              </div>
            </span>
          </div>
        </template>
      </FullCalendar>
      <!--Modal delete-->
      <DeleteEvent
        :showConfirmDeletion="showConfirmDeletion"
        @close="closeConfirmDeletion"
        @confirm="confirmDeletion"
      />
      <Drawer
        :isOpen="isDrawerOpen"
        @close="closeDrawer"
        :eventData="selectedEventData"
        :orgMembersList="orgMembersList"
      ></Drawer>
    </div>
    <!--Add event-->
    <div>
      <CModal :show="isEventModalOpen" @hide="closeEventModal">
        <template v-slot:header>
          <h3 style="padding-left: 1rem">{{ $t("Add Event") }}</h3>
          <CButton @click="closeEventModal">
            <i class="fa fa-times"></i>
          </CButton>
        </template>
        <template v-slot:footer>
          <CButton @click="closeEventModal" class="link">{{
            $t("Cancel")
          }}</CButton>
          <CButton
            @click="addEvent"
            color="primary"
            class="modal-confirm"
            data-testid="saveEventButton"
            :disabled="isDisabled"
          >
            {{ $t("Add Event") }}
          </CButton>
        </template>
        <div style="height: 520px; padding: 0 1rem !important">
          <ScopeSelections
            :show="isEventModalOpen"
            @usersChanged="selectedUsersChanged"
            @levelsChanged="selectedLevelsChanged"
            @rolesChanged="selectedRolesChanged"
            @selectAll="selectedAllUsers"
          />
          <div>
            <label>{{ $t("Title") }}</label>

            <el-input
              data-testid="eventTitleInput"
              v-model="eventTitle"
              :placeholder="$t('Event Title')"
            />
          </div>
          <div style="margin-top: 10px">
            <label>{{ $t("Subject") }}</label>

            <el-input
              data-testid="eventDescriptionInput"
              v-model="eventSubject"
              style="height: 80px"
              :rows="4"
              type="textarea"
              :placeholder="$t('Event Subject')"
            />
          </div>
          <div style="margin-top: 20px">
            <label>{{ $t("Attachments") }}</label>
            <FileDragDrop
              :allowedExtensions="allowedExtensions"
              size="xs"
              class="w-100"
              :key="index"
              :label="$t('Add documents')"
              @upload="(uploadFiles) => handleUpload(uploadFiles, 1)"
            />
          </div>
          <div
            v-if="uploadedFiles.length > 0"
            v-for="(file, index) in uploadedFiles"
            :key="index"
          >
            <div class="d-flex">
              <p style="font-size: 12px">{{ file.name }}</p>
              <span
                style="cursor: pointer; margin-left: 10px"
                @click="removeFileUploaded(index)"
              >
                <img
                  class="img"
                  :src="require('@/assets/Images/cross.png')"
                  alt="delete"
                />
              </span>
            </div>
          </div>
          <div style="margin-top: 5px">
            <div>
              <label>{{ $t("Date") }}</label>
            </div>
            <el-date-picker
              class="data-picker-event"
              data-testid="eventDateInput"
              v-model="eventDate"
              type="date"
              placeholder="Event Date"
              :size="size"
              :disabled-date="disabledDate"
              style="width: 100%"
            />

            <div style="margin-top: 5px">
              <label>{{ $t("Time") }}</label>
              <CInput
                class="eventTimeStyles"
                step="60"
                type="time"
                v-model="eventTime"
                placeholder="Event Time"
              />
            </div>
            <div style="margin-top: 5px">
              <label>{{ $t("Duration") }}</label>
              <el-select
                v-model="duration"
                placeholder="Select"
                class="duration-select"
              >
                <el-option
                  v-for="item in durationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
        </div>
      </CModal>
    </div>
    <div>
      <!--Update event-->
      <CModal :show="isEditEventModalOpen" @hide="closeEditEvent">
        <template v-slot:header>
          <h3>{{ $t("Edit Event") }}</h3>
          <CButton @click="closeEditEvent">
            <i class="fa fa-times"></i>
          </CButton>
        </template>
        <template v-slot:footer>
          <CButton @click="closeEditEvent" class="link">{{
            $t("Cancel")
          }}</CButton>
          <CButton
            @click="updateEvent"
            color="primary"
            class="modal-confirm"
            data-testid="saveEditEventButton"
            :disabled="isDisabled"
          >
            {{ $t("Edit Event") }}
          </CButton>
        </template>
        <div style="height: 520px; overflow: auto; padding: 20px !important">
          <ScopeSelections
            :show="isEditEventModalOpen"
            :preSelectedUsers="selectedEventUsers"
            @usersChanged="selectedUsersChanged"
            @levelsChanged="selectedLevelsChanged"
            @rolesChanged="selectedRolesChanged"
            @selectAll="selectedAllUsers"
          />

          <label>{{ $t("Title") }}</label>
          <CInput
            type="text"
            v-model="eventTitle"
            placeholder="Event Title"
            class="mb-2"
          />
          <label>{{ $t("Subject") }}</label>
          <CTextarea
            data-testid="EditeventDescriptionInput"
            type="text"
            v-model="eventSubject"
            placeholder="Event Subject"
            class="mb-2"
          />
          <label>{{ $t("Attachments") }}</label>

          <FileDragDrop
            :allowedExtensions="allowedExtensions"
            size="xs"
            class="w-100"
            :label="$t('Add documents')"
            @upload="(uploadFiles) => handleUpload(uploadFiles, 1)"
          />

          <div
            v-if="uploadedFiles.length > 0"
            v-for="(file, index) in uploadedFiles"
            :key="index"
          >
            <div class="d-flex">
              <p style="font-size: 12px">{{ file.name }}</p>
              <span
                style="cursor: pointer; margin-left: 10px"
                @click="deleteUploadedFile(file.id)"
              >
                <img
                  class="img"
                  :src="require('@/assets/Images/cross.png')"
                  alt="delete"
                />
              </span>
            </div>
          </div>
          <div class="d-flex justify-content-between mobileDateTime mt-2">
            <div class="w-50 pr-2">
              <label>{{ $t("Date") }}</label>
              <el-date-picker
                v-model="eventDate"
                type="date"
                placeholder="jj/mm/aaaa"
                :size="size"
                :disabled-date="disabledDate"
              />
            </div>
            <div class="w-50 pr-2">
              <label>{{ $t("Time") }}</label>
              <CInput
                step="60"
                type="time"
                v-model="eventTime"
                placeholder="Event Time"
              />
            </div>
            <div class="w-50">
              <label>{{ $t("Duration") }}</label>
              <!-- <CInput type="time" v-model="duration" step="1800" /> -->
              <el-select
                v-model="duration"
                placeholder="Select"
                class="duration-select"
              >
                <el-option
                  v-for="item in durationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
        </div>
      </CModal>
    </div>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import { OrganizationsService } from "@/services/organizations";
import { ProjectsService } from "@/services/projects";
import Drawer from "./components/Drawer.vue";
import moment from "moment";
import { FilesService } from "@/services/azureFiles";
import { AgendaService } from "@/services/agenda";
import { mapState } from "vuex";
import { showMessage } from "../../utils/helpers";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { ContentLoader } from "vue-content-loader";
import DeleteEvent from "./components/DeleteEvent.vue";
import { useGlobalStore } from "../../stores/store";
import { useUserStore } from "@/stores/UserStore";

import { mapWritableState } from "pinia";
import { ElLoading } from "element-plus";
import ScopeSelections from "@/components/ScopeSelections";

export default {
  name: "Agenda",
  components: {
    FullCalendar,
    vSelect,
    Treeselect,
    Drawer,
    OrganizationSelector,
    ContentLoader,
    DeleteEvent,
    ScopeSelections,
  },
  data() {
    return {
      selectedAll: false,
      globalStore: useGlobalStore(),
      userStore: useUserStore(),
      allowedExtensions: [
        "jpeg",
        "jpg",
        "png",
        "gif",
        "csv",
        "xlsx",
        "txt",
        "zip",
        "7p",
        "tar",
        "gz",
        "ppt",
        "pptx",
        "docx",
        "pdf",
      ],
      selectAllParticipants: false,
      selectedEventForDropdown: null,
      showDropDown: false,
      customKey: 0,
      id: "id[0]",
      options: {
        slotMinTime: "08:00",
        nowIndicator: true,
        height: "auto",
        viewRender: function (view) {},

        allDaySlot: false,
        eventDidMount: function (arg) {
          if (arg.event.extendedProps.type === "training") {
          }
        },

        eventRender: function (event, element, view) {},
        eventClassNames: function (arg) {
          if (arg.event.extendedProps.type === "Training") {
            return ["training-event"];
          } else {
            return ["meeting-event"];
          }
        },
        viewWillUnmount: (arg) => this.removingEventsDates(arg),
        viewDidMount: (arg) => this.addEventsDates(arg),
        locale: "en",
        stickyFooterScrollbar: true,

        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: "timeGridWeek",
        headerToolbar: {
          left: "dayGridMonth timeGridWeek listDay",
          center: "title",
          right: "prev next",
        },
        editable: false,
        selectable: true,
        weekends: false,
        buttonText: {
          month: "Month",
          week: "Week",
          list: "List",
          today: "Today",
        },
        eventClick: this.handleEventClick,
        events: [],
      },
      isEditEventModalOpen: false,
      isEventModalOpen: false,
      currentEvents: [],
      eventTitle: "",
      eventSubject: "",
      eventDate: "",
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() - 86400000 &&
            (time.getDay() === 0 || time.getDaya() === 6)
          );
        },
      },
      eventTime: "08:00",
      duration: "1",
      organization: "",
      orgOptions: [],
      project: "",
      projectsOptions: [],
      selectedUsers: [],
      selectedLevels: [],
      selectedRoles: [],
      usersOptions: [],
      isDrawerOpen: false,
      selectedEventData: null,
      uploadedFiles: [],
      showConfirmDeletion: false,
      eventToDelete: null,
      isLoading: false,
      currentUserId: "",
      treeselectKey: "",
      selectedFilter: "users",
      durationOptions: [
        {
          value: "0.5",
          label: "30 minutes",
        },
        {
          value: "1",
          label: "60 minutes",
        },
        {
          value: "1.5",
          label: "90 minutes",
        },
        {
          value: "2",
          label: "120 minutes",
        },
        {
          value: "2.5",
          label: "150 minutes",
        },
        {
          value: "3",
          label: "180 minutes",
        },
      ],
      selectedEventUsers: [],
      eventToEditId: "",
      orgMembersList: [],
      agendaPermissions: [],
      uploadUrl: "",
      fileId: "",
    };
  },
  async created() {
    await this.getActiveUser();
  },
  watch: {
    selectedUsers: {
      handler(newVal) {
        this.customKey++;
      },
      deep: true,
    },

    options(val) {},

    selectedEvent: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectedEventData = newVal;
          this.isDrawerOpen = true;
        }
      },
    },
    selectedTraining: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.selectedEventData = newVal;
          this.isDrawerOpen = true;
        }
      },
    },
  },
  mounted() {
    this.agendaPermissions = this.userStore.currentPermissions[0].find(
      (p) => p.name === "Agenda"
    ).permissions;
    this.refreshEverything();
    this.options.locale = this.userData.language;
    this.options.buttonText.month =
      this.userData.language == "fr" ? "Mois" : "Month";
    this.options.buttonText.today =
      this.userData.language == "fr" ? "Aujourd'hui" : "Today";
    this.options.buttonText.week =
      this.userData.language == "fr" ? "Semaine" : "Week";
    this.options.buttonText.list =
      this.userData.language == "fr" ? "Liste" : "List";

    document.addEventListener("click", this.clickOutSideDropdown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSideDropdown);
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    isDisabled() {
      this.selectedUsers = this.selectedUsers || [];
      this.selectedLevels = this.selectedLevels || [];
      this.selectedRoles = this.selectedRoles || [];

      const areBasicConditionsNotMet =
        !this.eventTitle.trim() ||
        !this.eventSubject.trim() ||
        !this.eventDate ||
        !this.eventTime;
      const isScopeEmpty =
        this.selectedUsers.length === 0 &&
        this.selectedLevels.length === 0 &&
        this.selectedRoles.length === 0;

      return areBasicConditionsNotMet || (isScopeEmpty && !this.selectedAll);
    },

    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),
  },
  beforeRouteLeave(to, from, next) {
    // ...
    if (from.name === "Agenda") {
      this.$store.commit("updateSelectedEvent", null);
      this.$store.commit("updateSelectedTraining", null);
      this.closeDrawer();
    }
    next();
  },

  methods: {
    doesHaveRight(rightName) {
      if (this.agendaPermissions.length > 0)
        return this.agendaPermissions.find((right) => right.name === rightName)
          .status;
    },
    selectedUsersChanged(selectedUsers) {
      this.selectedUsers = selectedUsers;
    },
    selectedLevelsChanged(selectedLevels) {
      this.selectedLevels = selectedLevels;
    },
    selectedRolesChanged(selectedRoles) {
      this.selectedRoles = selectedRoles;
    },
    selectedAllUsers(selectAll) {
      this.selectedAll = selectAll;
    },
    disabledDate(time) {
      const date = new Date();
      date.setHours(0, 0, 0, 0);
      return (
        time.getTime() < date.getTime() ||
        time.getDay() === 0 ||
        time.getDay() === 6
      );
    },

    clickArg(event) {},
    clickOutSideDropdown(event) {
      const dropdownElement = this.$refs.dropdown;
      if (dropdownElement && dropdownElement.contains(event.target)) {
        if ((this.showDropDown = true)) {
          this.showDropDown = false;
        }
      }
    },
    openDropDown(event) {
      this.showDropDown = !this.showDropDown;
      this.selectedEventForDropdown = event;
    },
    async getActiveUser() {
      this.currentUserId = this.userData.id;
    },
    async refreshEverything() {
      this.getEvents();
      this.getOrgMembers();
    },
    async getListProject() {
      const response = await ProjectsService.list_projects({
        organization_name:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
      });
      const listProject = response.data.data;
      this.projectsOptions = listProject.map((pr) => ({
        code: pr.name,
        value: pr.name,
      }));
    },
    removingEventsDates(arg) {
      if (arg.view.type == "timeGridWeek") {
        this.options.events = this.options.events.map((el) => {
          return el;
        });
      }
    },
    addEventsDates(arg) {
      if (arg.view.type == "timeGridWeek") {
        this.options.events = this.options.events.map((el) => {
          const { start, end } = this.startEnds(el);
          return { ...el, start, end };
        });
      }
      if (arg.view.type == "dayGridMonth") {
        this.options.events = this.options.events.map((el) => {
          let { start, end } = this.startEnds(el);

          start = new Date(start);
          const year = start.getFullYear();
          const month = String(start.getMonth() + 1).padStart(2, "0");
          const day = String(start.getDate()).padStart(2, "0");
          end = new Date(end);
          const endYear = end.getFullYear();
          const endMonth = String(end.getMonth() + 1).padStart(2, "0");
          const endDay = String(end.getDate()).padStart(2, "0");
          start = `${year}-${month}-${day}`;
          end = `${endYear}-${endMonth}-${endDay}`;
          el.start = start;
          el.end = end;
          return { start, end, ...el };
        });
      }
    },
    async getOrgMembers() {
      try {
        const { data } = await OrganizationsService.list_org_members({
          organization_name:
            this.currentOrganization.name ||
            this.currentOrganization.organization,
          project_name: this.currentProject.name,
        });
        this.orgMembersList = data.data;
        const usersOptions = [];
        const response = data.data;
        if (this.usersOptions.length > 0) {
          this.usersOptions = [];
        }
        const seenUserNames = new Set();
        response.forEach((user) => {
          const userName = `${user.firstname} ${user.lastname}`;
          if (!seenUserNames.has(user._id)) {
            seenUserNames.add(user._id);
            usersOptions.push({
              value: user._id,
              label: userName,
            });
          }
        });
        this.globalStore.setScopeUsersOptions(usersOptions);
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    startEnds(event) {
      const [startTime, endTime] = event.time.split(" - ");
      const startDate = new Date(event.date + "T" + startTime + ":00Z");
      const endDate = new Date(event.date + "T" + endTime + ":00Z");

      const start = startDate.toISOString();
      const end = endDate.toISOString();

      return { start, end };
    },
    handleEventClick(event) {
      const { title, extendedProps, start, time, link } = event.event;
      const eventDetails = {
        title,
        date: start,
        time,
        link,
        ...extendedProps,
      };
      this.selectedEventData = eventDetails;
    },
    openEventModal() {
      this.resetFields();
      this.isEventModalOpen = true;
    },
    closeEventModal() {
      this.resetFields();
      this.isEventModalOpen = false;
    },
    changeViewToWeek() {
      this.options.initialView = "timeGridWeek";
    },
    closeEditEvent() {
      this.resetFields();
      this.isEditEventModalOpen = false;
    },
    resetFields() {
      this.selectedEventUsers = [];
      this.eventTitle = "";
      this.eventSubject = "";
      this.eventDate = "";
      this.eventTime = "08:00";
      this.uploadedFiles = [];
      this.selectedUsers = [];
      this.treeselectKey = Math.random().toString();
    },

    async handleUpload(file) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const data = await FilesService.getUploadUrl("events");
      this.uploadUrl = data.upload_url;
      this.fileId = data.file_id;
      this.uploadedFiles.push({
        name: file.name,
        id: data.file_id,
        fileUrl: data.upload_url,
        file: file,
      });
      if (this.isEditMode) {
        this.editedUploadedFiles.push(file.name);
      }
      loading.close();
    },
    async addEvent() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      try {
        const [startHour, startMinute] = (this.eventTime || "08:00")
          .split(":")
          .map(Number);

        const durationMinutes = (this.duration || 1) * 60;

        let endHour = startHour + Math.floor(durationMinutes / 60);
        let endMinute = startMinute + (durationMinutes % 60);

        // Adjust end time if minutes overflow
        if (endMinute >= 60) {
          endHour += 1;
          endMinute -= 60;
        }
        // Format end time
        const formattedStartHour = String(startHour).padStart(2, "0");
        const formattedStartMinute = String(startMinute).padStart(2, "0");
        const formattedEndHour = String(endHour % 24).padStart(2, "0"); // Mod 24 to handle midnight correctly
        const formattedEndMinute = String(endMinute).padStart(2, "0");
        // Create time range string
        const startTime = `${formattedStartHour}:${formattedStartMinute}`;
        const endTime = `${formattedEndHour}:${formattedEndMinute}`;
        const timeRange = `${startTime} - ${endTime}`;
        const start = moment(this.eventDate).format("YYYY-MM-DD");
        const org =
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization;
        const usersToSend = this.selectedAll
          ? this.globalStore.scopeUsersOptions.map((user) => user.value)
          : this.selectedUsers;
        let uploadFileStatus = false;
        if (this.uploadedFiles.length > 0) {
          for (let index = 0; index < this.uploadedFiles.length; index++) {
            const element = this.uploadedFiles[index];

            const uploadFileData = await FilesService.UploadFile(
              element.file,
              element.fileUrl
            );
            uploadFileStatus = uploadFileData.success;
          }
          if (uploadFileStatus) {
            const newEvent = {
              organization: org,
              project: this.currentProject.name,
              participants: this.selectAllParticipants
                ? this.usersOptions.map((user) => {
                    return user.value;
                  })
                : this.selectedUsers,
              title: this.eventTitle,
              subject: this.eventSubject,
              attachments: [],
              date: start,
              time: this.convertToUTC(timeRange),
              allDay: false,
              users: usersToSend,
              levels: this.selectedAll ? [] : this.selectedLevels,
              roles: this.selectedAll ? [] : this.selectedRoles,
            };
            if (this.uploadedFiles.length > 0) {
              newEvent.attachments = this.uploadedFiles.map((el) => ({
                id: el.id,
                name: el.name,
              }));
            }

            const { data, res } = await AgendaService.create_event(newEvent);
            const transformedEvent = {
              organization: newEvent.organization,
              project: newEvent.project,
              title: newEvent.title,
              subject: newEvent.subject,
              date: newEvent.date,
              time: newEvent.time,
              attachments: newEvent.attachments.map((filename) => ({
                filename: filename,
              })),
              users: newEvent.users,
              levels: newEvent.levels,
              roles: newEvent.roles,
            };
            this.options.events.push(transformedEvent);
            if (data.success) {
              showMessage("success", "Event created successfully !");
            } else {
              showMessage("error", data.message);
            }
          }
        } else {
          const newEvent = {
            organization: org,
            project: this.currentProject.name,
            participants: this.selectAllParticipants
              ? this.usersOptions.map((user) => {
                  return user.value;
                })
              : this.selectedUsers,
            title: this.eventTitle,
            subject: this.eventSubject,
            attachments: [],
            date: start,
            time: this.convertToUTC(timeRange),
            allDay: false,
            users: usersToSend,
            levels: this.selectedAll ? [] : this.selectedLevels,
            roles: this.selectedAll ? [] : this.selectedRoles,
          };
          const { data, res } = await AgendaService.create_event(newEvent);

          if (data.success) {
            showMessage("success", "Event created successfully !");
          } else {
            showMessage("error", data.message);
          }
        }
        this.closeEventModal();
        this.resetFields();
        this.options.events = this.options.events.filter((event) => {
          return (event.participants || event.users).includes(
            this.currentUserId
          );
        });

        this.getEvents();
      } catch (error) {
        console.error("Error adding event:", error);
      }
      loading.close();
    },
    convertToUTC(timeRange) {
      const [startTime, endTime] = timeRange.split(" - ");

      // Convert to Date objects
      const startDate = new Date();
      const [startHours, startMinutes] =
        this.convertTo24Hour(startTime).split(":");
      startDate.setHours(startHours, startMinutes, 0, 0);

      const endDate = new Date();
      const [endHours, endMinutes] = this.convertTo24Hour(endTime).split(":");
      endDate.setHours(endHours, endMinutes, 0, 0);

      // Get UTC hours and minutes
      const startUTC = `${String(startDate.getUTCHours()).padStart(
        2,
        "0"
      )}:${String(startDate.getUTCMinutes()).padStart(2, "0")}`;
      const endUTC = `${String(endDate.getUTCHours()).padStart(
        2,
        "0"
      )}:${String(endDate.getUTCMinutes()).padStart(2, "0")}`;

      return `${startUTC} - ${endUTC}`;
    },
    convertTo24Hour(time) {
      const [timeString, modifier] = time.split(" ");
      let [hours, minutes] = timeString.split(":");

      if (hours === "12") {
        hours = "00";
      }

      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
    },
    async removeFileUploaded(index) {
      const filenameToRemove = this.uploadedFiles[index];
      try {
        await FilesService.delete_file({ filename: filenameToRemove });
        this.uploadedFiles.splice(index, 1);
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    },
    async deleteUploadedFile(id) {
      const attachmentName = this.uploadedFiles.find((el) => el.id).name;
      const { organization, project, title } = this.selectedEventData;
      const fileToDelete = {
        organization,
        project,
        attachment_name: attachmentName,
        title,
      };
      try {
        const response = await FilesService.deleteFile(id, "events");
        this.uploadedFiles.splice(
          this.uploadedFiles.find((el) => el.id),
          1
        );
      } catch (error) {
        console.error("Failed to delete attachment: ", error);
      }
    },
    async getEvents() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });

      try {
        const response = await AgendaService.list_events();
        if (response.data.success) {
          this.options.events = response.data.data.filter((event) => {
            return (
              Array.isArray(event.participants) &&
              event.participants.includes(this.currentUserId) &&
              (event.organization === this.currentOrganization.name ||
                event.organization === this.currentOrganization.organization ||
                event.organization === this.currentOrganization) &&
              event.project === this.currentProject.name
            );
          });

          this.options.events = this.options.events.map((el) => {
            const { start, end } = this.startEnds(el);
            const { localStart, localEnd } = this.convertToUserTimezone(
              start,
              end
            );
            return {
              ...el,
              start: localStart,
              end: localEnd,
              trainingColor: el.color,
            };
          });

          this.resetFields();
        } else {
          console.error("Error getting events:", response.error);
        }
      } catch (error) {
        console.error("Error in getEvents:", error);
        loading.close();
      } finally {
        loading.close();
      }
    },
    convertToUserTimezone(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      const localStartDate = startDate.toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      const localEndDate = endDate.toLocaleString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      return {
        localStart: new Date(localStartDate).toISOString(),
        localEnd: new Date(localEndDate).toISOString(),
      };
    },
    openConfirmDeletion(event) {
      const { organization, project, _id } = event.extendedProps;
      const { title } = event;
      const eventToDelete = {
        organization,
        project,
        _id,
        title,
      };
      this.eventToDelete = eventToDelete;
      this.showConfirmDeletion = true;
    },
    closeConfirmDeletion() {
      this.showConfirmDeletion = false;
    },
    async confirmDeletion() {
      if (this.eventToDelete) {
        const event = this.eventToDelete;
        const { data, res } = await AgendaService.delete_event(event);
        if (res.status === 200) {
          showMessage("success", "Event deleted successfully !");
        } else {
          showMessage("error", "Event deleted failed !");
        }
        this.closeConfirmDeletion();
        this.getEvents();
        this.eventToDelete = null;
      }
    },
    async deleteEvent(event) {
      this.openConfirmDeletion(event);
    },
    async editEvent(event) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      const {
        _id,
        organization,
        project,
        participants,
        subject,
        time,
        attachments,
      } = event.extendedProps;
      this.eventToEditId = _id;
      const fileUploaded = attachments;
      const { title, startStr } = event;
      const [date] = startStr.split("T");
      this.eventTitle = title;
      this.eventSubject = subject;
      this.eventDate = date;
      this.eventTime = time.split(" - ")[0];
      this.organization = `${organization} / ${project}`;
      this.project = project;

      // Calculate duration
      const [startTime, endTime] = time.split(" - ");
      const durationInMilliseconds =
        new Date(`1970-01-01T${endTime}:00`).getTime() -
        new Date(`1970-01-01T${startTime}:00`).getTime();
      const durationInHours = durationInMilliseconds / (1000 * 60 * 60);

      const durationOption = this.durationOptions.find(
        (option) => parseFloat(option.value) === durationInHours
      );
      if (durationOption) {
        this.duration = durationOption.value;
      } else {
        console.log(
          "No exact match found for duration. Consider handling partial hours or adding more options."
        );
      }

      this.selectedEventUsers = participants;

      this.isEditEventModalOpen = true;
      this.uploadedFiles = fileUploaded;
      loading.close();
    },

    async updateEvent() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });

      if (
        this.eventTitle &&
        this.eventSubject &&
        this.eventDate &&
        this.eventTime &&
        this.organization &&
        this.project &&
        this.selectedUsers.length > 0
      ) {
        try {
          const [startHour, startMinute] = (this.eventTime || "08:00")
            .split(":")
            .map(Number);
          const durationHours = parseFloat(this.duration || "1");

          let durationHour = Math.floor(durationHours);
          let durationMinute = (durationHours - durationHour) * 60;

          let endHour = startHour + durationHour;
          let endMinute = startMinute + durationMinute;

          if (endMinute >= 60) {
            endHour += 1;
            endMinute -= 60;
          }

          // Format end time
          const formattedEndHour = String(endHour).padStart(2, "0");
          const formattedEndMinute = String(endMinute).padStart(2, "0");

          const start = moment(this.eventDate).format("YYYY-MM-DD");
          const startTime = `${String(startHour).padStart(2, "0")}:${String(
            startMinute
          ).padStart(2, "0")}`;
          const endTime = `${formattedEndHour}:${formattedEndMinute}`;

          // Convert to UTC
          const utcTimeRange = this.convertToUTC(`${startTime} - ${endTime}`);

          let uploadFileStatus = true;
          const currentTitle = this.selectedEventData.title;

          const currentAttachments =
            this.selectedEventData.attachments.map((file) => ({
              ...file,
              id: file.id,
              name: file.name,
            })) || [];
          let newAttachments = this.uploadedFiles
            .filter((file) => !currentAttachments.includes(file))
            .map((file) => ({ ...file, id: file.id, name: file.name }));
          for (let index = 0; index < newAttachments.length; index++) {
            const element = this.uploadedFiles[index];

            if (element.file) {
              const uploadFileData = await FilesService.UploadFile(
                element.file,
                element.fileUrl
              );
              uploadFileStatus = uploadFileData.success;
            }
          }
          if (uploadFileStatus) {
            newAttachments = newAttachments.map((el) => ({
              id: el.id,
              name: el.name,
            }));
            const org = this.organization.split("/");
            const neededOrganization = org[0].trim();
            const project = org[1].trim();

            const updatedEvent = {
              _id: this.eventToEditId._id,
              organization: neededOrganization,
              project: project,
              participants: this.selectedUsers,
              title: this.eventTitle,
              subject: this.eventSubject,
              attachments: [],
              date: start,
              time: utcTimeRange, // Use the converted UTC time range here
            };
            updatedEvent.attachments = [...newAttachments];
            const usersToSend = this.selectedAll
              ? this.globalStore.scopeUsersOptions.map((user) => user.value)
              : this.selectedUsers;

            const response = await AgendaService.edit_event({
              organization:
                this.currentOrganization.name ||
                this.currentOrganization.organization,
              project: this.currentProject.name,
              _id: this.selectedEventData._id,
              title: currentTitle,
              users: usersToSend,
              levels: this.selectedAll ? [] : this.selectedLevels,
              roles: this.selectedAll ? [] : this.selectedRoles,
              update_data: updatedEvent,
            });
            if (response.data.success) {
              showMessage("success", "Event updated successfully !");
            } else {
              showMessage("error", "Failed to update event !");
            }
            this.closeEditEvent();
            this.resetFields();
            this.getEvents();
          }
        } catch (error) {
          console.error("Error updating event:", error);
        }
      } else {
        showMessage("warning", "Please fill in all the required fields");
      }
      loading.close();
    },

    toggleDrawer(event) {
      const { title, extendedProps, start, time, attachments } = event;
      const date = start;
      const selectedEventData = {
        title,
        start: date,
        time,
        attachments,
        ...extendedProps,
      };
      this.selectedEventData = selectedEventData;
      this.isDrawerOpen = true;
    },
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    formatTime(dateTime) {
      return new Date(dateTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>
<style>
.training-event {
  background-color: white;
  border-color: #6e85b7;
  color: white;
}
.fc .fc-scrollgrid {
  max-height: calc(100vh - 300px);
}
.fc .fc-button {
  width: 45px;
  height: 45px;
}

.fc-prev-button:hover {
  background: rgb(224, 223, 223) !important;
}
.fc-next-button:hover {
  background: rgb(224, 223, 223) !important;
}
.fc .fc-scroller {
  overflow: hidden auto !important;
}
.meeting-event {
  background-color: white !important;
  border-color: #0071ce;
  color: white;
}
.fc-toolbar-chunk {
  margin-left: -9px !important;
}
.fc .fc-scrollgrid-section-sticky > * {
  position: relative;
}
.fc-event-main,
.fc-list-event-title {
  display: flex;
}
.fc-list-event-title div:first-child,
.fc-event-main div:last-child {
  width: fit-content;
}
.fc-list-event-title div:last-child,
.fc-event-main div:last-child {
  width: 100%;
}
.fc-timegrid-event .fc-event-main {
  display: flex;
}
.fc-timegrid-event .fc-event-main div {
  display: flex;
}
fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future.fc-list-event.training-event:hover {
  background-color: red;
}

.fc .fc-list-event:hover td {
  background-color: unset;
}
.fc-list-event-dot {
  border: calc(var(--fc-list-event-dot-width) / 2) solid white !important;
}
.fc-theme-standard .fc-list-table td {
  border: unset;
}
.fc th {
  height: 50px;
  border-bottom: 1px solid #a5a4a4 !important;
}
.fc td,
.fc th {
  vertical-align: middle;
}
.fc-timegrid-event .fc-event-main {
  padding: 0;
}
</style>
<style lang="scss">
table tr {
  height: unset !important;
}
.blue {
  color: #0071ce !important;
}
.event-title {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; /* Adjust percentage as needed */
  color: black;
}
.events_meeting {
  position: absolute;
  width: 4px;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: #0071ce;
}
.events_training {
  position: absolute;
  width: 4px;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: #6e85b7;
}
.fa-ellipsis-v {
  color: black;
  display: none;
}
.options {
  color: black;
}
@media (max-width: 970px) {
  .AgendaPage {
    margin-top: 50px;
  }
}
@media (max-width: 650px) {
  .AgendaPage {
    margin-top: 0;
  }
}
@media (max-width: 437px) {
  .options {
    display: none;
  }
  .fa-ellipsis-v {
    display: block;
  }
}
.options_event {
  position: absolute;
  top: 24px;
  right: -10px;
  align-items: center;
  min-width: 30px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.event-training {
  background-color: #6e85b7 !important;
}
b {
  margin-right: 3px;
}
.fc-toolbar-chunk button {
  width: 60px;
}

.img {
  margin-top: -2px;
  width: 12px;
  height: 12px;
}
.modal-confirm {
  width: max-content;
  border-radius: 0px 0px 0px 25px;
}

.btn-add {
  width: max-content;
  padding: 0 20px;
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  height: 40px;
}
.add-container {
  width: 100%;
}
.vs__open-indicator {
  cursor: pointer;
}

@media (max-width: 444px) {
  .mobileDateTime {
    display: flex;
    flex-wrap: wrap;
  }
}

.filter_btn {
  cursor: pointer;
  font-size: 14px;
}
.filter_btn:hover {
  color: #0071ce;
}
</style>

<style scoped>
.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 105px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}
::v-deep .modal-footer {
  padding: 0.75rem 39px !important;
}
::v-deep .modal-dialog {
  top: unset !important;
  left: unset !important;
}
::v-deep .eventTimeStyles input {
  border: 1px solid rgb(222, 220, 220) !important;
}
</style>
