import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const FILES_ENDPOINTS = {
    getUploadUrl: (container) => `${BASE_URL}api/files/get-upload-url/?container=${container}`,
    getFileUrl: (fileId, container) => `${BASE_URL}api/files/get-download-url/?id=${fileId}&container=${container}`,
    deleteFile: `${BASE_URL}api/files/delete-file/`,
    storageToken: `${BASE_URL}api/files/get-storage-token/`,
};

class Files {
    async getUploadUrl(container="news") {
        /* 
        NOTE:
            When uploading the image of a destination keep the below structure:
            container = "news" default it can also be "events" or "modules"
        */
        const { data } = await request({
        url: FILES_ENDPOINTS.getUploadUrl(container),
        method: "GET",
        });
        return data;
    }

    async UploadFile(file, UploadUrl) {
        try {
        const res = await fetch(UploadUrl, {
            method: "PUT",
            body: file,
            headers: {
                "x-ms-blob-type": "BlockBlob"
            },
        });
    
        if (res.status === 201) {
            return {"success": true};
        }
        console.log("res", res);
        return res;
        } catch (error) {
        console.error(error);
        return error;
        }

    }

    async getFileUrl(fileId, container) {
        /* 
            container = "events" default it can also be "modules"
        */
        const res = await request({
        url: FILES_ENDPOINTS.getFileUrl(fileId, container),
        method: "GET",
        });
        return res;
    }

    async getStorageToken() {
        /* 
        Note:
            When you get the token you can access all destinations images by using the bellow url:
            "https://stmzgdsengage.blob.core.windows.net/news/{image_id}?{token}
        */
        const res = await request({
        url: FILES_ENDPOINTS.storageToken,
        method: "GET",
        });
        return res;
    }

    async deleteFile(fileId, container) {
        /* 
            container = "events" default it can also be "modules"
        */
        const res = await request({
        url: FILES_ENDPOINTS.deleteFile,
        method: "DELETE",
        body: {
            id: fileId,
            container
        },
        });
        return res;
    }
}

export const FilesService = new Files();
