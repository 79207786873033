<template>
  <div>
    <div class="drawer" :class="{ 'is-open': isOpen, 'is-visible': isVisible }">
      <div
        class="drawer__overlay"
        :style="{ transitionDuration: `${speed}ms` }"
      ></div>
      <div
        class="drawer__content"
        v-click-away="closeDrawer"
        :style="{
          transitionDuration: `${speed}ms`,
          backgroundColor: backgroundColor,
        }"
      >
        <slot></slot>
        <div class="d-flex justify-content-between">
          <h3 class="p-3">{{ eventData ? eventData.title : "" }}</h3>
          <CButton @click="closeDrawer">
            <i class="fa fa-times"></i>
          </CButton>
        </div>
        <div class="drawer-slider" v-if="eventData">
          <div class="d-flex justify-content-between">
            <div>
              <p>
                {{ eventData.subject }}
              </p>
            </div>
            <div class="join-btn">
              <CButton class="text-white" @click="joinMeeting">{{
                $t("Join the meeting")
              }}</CButton>
              <i class="fa fa-video text-white"></i>
            </div>
          </div>
          <div class="event-info">
            <div>
              <h4>{{ $t("Schedules") }}</h4>
              <div class="d-flex flex-column">
                <label class="text-secondary">Date</label>
                <span>{{ formatDate(eventData.date) }}</span>
                <label class="mt-3 text-secondary">Time</label>
                <span>{{ eventData.time }}</span>
              </div>
            </div>
            <div class="">
              <h4 class="">{{ $t("Documents") }}</h4>
              <div v-if="eventData.attachments.length > 0">
                <div
                  v-for="(attachment, index) in eventData.attachments"
                  :key="index"
                  class="attachment-filename"
                  @click.prevent="downloadFileWhenClicking(eventData, attachment)"
                >
                  <i class="fa fa-file mr-1"></i>
                  <span class="filename">{{ attachment.name }}</span>
                </div>
              </div>
              <template v-else>
                <p>{{ $t("No file uploaded") }}</p>
              </template>
            </div>
          </div>
        </div>
        <!--Participants-->
        <div>
          <div>
            <Carousel :eventData="eventData" :orgMembersList="orgMembersList" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive } from "vue3-click-away";
import Carousel from "./Carousel.vue";
import { AgendaService } from "../../../services/agenda";
import { FilesService } from "@/services/azureFiles";

import { showMessage } from "../../../utils/helpers";
export default {
  name: "Drawer",
  components: {
    Carousel,
  },
  directives: {
    ClickAway: directive,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
      default: "40%",
    },
    // Transition Speed in Milliseconds
    speed: {
      type: Number,
      required: false,
      default: 300,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#fafafa",
    },
    eventData: {
      type: Object,
      default: null,
    },
    trainingData: {
      type: Object,
      default: null,
    },
    orgMembersList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      isVisible: false,
      isTransitioning: false,
    };
  },
  watch: {
    isOpen(val) {
      this.isTransitioning = true;
      if (val) {
        this.toggleBackgroundScrolling(true);
        this.isVisible = true;
        this.eventData.attachments.forEach(el=>{
                 this.downloadFile(el.id);
            }
            );

      } else {
        this.toggleBackgroundScrolling(false);
        setTimeout(() => (this.isVisible = false), this.speed);
      }
      setTimeout(() => (this.isTransitioning = false), this.speed);
    },
  },
  methods: {
    joinMeeting() {
      if (this.eventData && this.eventData.link) {
        window.open(this.eventData.link, "_blank");
      } else {
        showMessage("error", $t("Meeting link not available"));
      }
    },
    async downloadFile(id){
        const {res,data}=await FilesService.getFileUrl(id,"events");
        if(res.status==200){
            this.eventData.attachments=this.eventData.attachments.map(el=>{
                if(el.id==id){
                    return {...el,url:data.download_url}
                }else{
                    return el
                }
            })
        }
    },
    async downloadFileWhenClicking(eventData, attachment) {
            try {
        const response = await fetch(attachment.url);
        const blob = await response.blob();

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download =attachment.name; // Set the desired filename
        link.click();
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    toggleBackgroundScrolling(enable) {
      const body = document.querySelector("body");
      body.style.overflow = enable ? "hidden" : null;
    },
    closeDrawer() {
      if (!this.isTransitioning) {
        this.$emit("close");
      }
    },
    formatDate(dateTime) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const dateToReturn =
        this.$i18n.locale == "fr"
          ? new Date(dateTime).toLocaleDateString("fr-FR", options)
          : new Date(dateTime).toLocaleDateString("en-US", options);
      return dateToReturn;
    },
    formatTime(dateTime) {
      return new Date(dateTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    this.isVisible = this.isOpen;
    if(this.eventData!=null && Object.keys(this.eventData).includes('attachments')){
      this.eventData.attachments.forEach(el=>{
        this.downloadFile(el.id);
      }
    );
  }
  },
  components: { Carousel },
};
</script>

<style lang="scss" scoped>
.event-info {
  display: flex;
  justify-content: space-between;
}
.attachment-filename {
  display: flex;
  align-items: center;
  padding: 8px;
}
.attachment-filename:hover {
  cursor: pointer;
}

.filename {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}
.filename:hover {
  text-decoration: underline;
}
.attachment-filename:first-child {
  border-top: 1px solid #ccc;
}
.attachment-filename + .attachment-filename {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.drawer-slider {
  // background-color: rgba(218, 218, 218, 0.226);
  width: 100%;
  margin: 0 auto;
  // margin-top: 20px;
  padding: 15px;
  height: 50%;
  // padding-left: 7px;
}
.join-btn {
  display: flex;
  align-items: center;
  background-color: #0071ce;
  border-radius: 0px 0px 0px 25px;
  height: 40px;
  margin-top: 9px;
  // margin-right: 17px;
  padding: 0 4px;
  // width: max-content;
}
.drawer__content {
  max-width: 40%;
}
@media (max-width: 613px) {
  .event-info {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 808px) {
  .drawer__content {
    max-width: 70%;
  }
}
@media (max-width: 593px) {
  .drawer__content {
    max-width: 80%;
  }
}
@media (max-width: 350px) {
  .drawer__content {
    max-width: 100%;
  }
}
.drawer {
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }

  &.is-open {
    .drawer__overlay {
      opacity: 0.5;
    }

    .drawer__content {
      transform: translateX(0);
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    opacity: 0;
    transition-property: opacity;
    background-color: #fff;
    user-select: none;
  }

  &__content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    overflow: auto;
    transition-property: transform;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    box-shadow: 0 2px 6px #777;
  }
}
</style>
