<template>
  <div>
    <div class="carousel_nav">
      <h4 class="pl-3">
        {{ $t("Participants") }}:
        {{ eventData ? eventData.participants.length : "" }}
      </h4>
      <div class="pr-3">
        <el-button link @click="prev" class="nav_btn"
          ><el-icon class="el-icon--left"><ArrowLeft /></el-icon
        ></el-button>
        <el-button link @click="next" class="nav_btn"
          ><el-icon class="el-icon--right"><ArrowRight /></el-icon
        ></el-button>
      </div>
    </div>
    <Carousel
      ref="carousel"
      :itemsToShow="3"
      :wrapAround="false"
      :transition="500"
      v-if="eventData"
    >
      <Slide
        v-for="(user, index) in eventData.participants"
        :key="index"
        class="mt-3"
      >
        <div>
          <div class="avatar_ribbon" v-if="userIsInAvatarList(user)">
            <DefaultAvatarHome
              :primaryColor="findAvatarByUser(user).skinTone"
              :selectedHair="findAvatarByUser(user).selectedHair"
              :topClothe="findAvatarByUser(user).selectedTopClothes"
              :bottomClothe="findAvatarByUser(user).selectedBottomClothes"
              :facialHair="findAvatarByUser(user).selectedFacialHair"
              :facialExpression="findAvatarByUser(user).selectedExpression"
              :facialHairPosition="facialHairPosition"
              :shoes="findAvatarByUser(user).selectedShoes"
              :topClothesPosition="topClothesPosition"
              :bottomClothesPosition="bottomClothesPosition"
            />
          </div>
          <div class="avatar_ribbon" v-else>
            <DefaultAvatarHome
              :primaryColor="avatar.skinTone"
              :selectedHair="avatar.selectedHair"
              :topClothe="avatar.selectedTopClothes"
              :bottomClothe="avatar.selectedBottomClothes"
              :facialHair="avatar.selectedFacialHair"
              :facialExpression="avatar.selectedExpression"
              :facialHairPosition="facialHairPosition"
              :shoes="avatar.selectedShoes"
              :topClothesPosition="topClothesPosition"
              :bottomClothesPosition="bottomClothesPosition"
            />
          </div>
          <span class="d-flex justify-content-center mt-3">{{
            getNameById(user)
          }}</span>
        </div>
      </Slide>
      <!-- <template #addons>
      <Navigation />
    </template> -->
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { images } from "../../../assets/Images/images";
import { ProfileService } from "@/services/profile";
import DefaultAvatarHome from "../../home/components/DefaultAvatarHome.vue";
import { ArrowLeft, ArrowRight } from "@element-plus/icons-vue";
export default {
  name: "Autoplay",
  components: {
    ArrowLeft,
    ArrowRight,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    DefaultAvatarHome,
  },
  props: {
    eventData: {
      type: Object,
      default: null,
    },
    orgMembersList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      participant: images.avatar.profileHome,
      listNames: [],
      topClothesPosition: "108px",
      bottomClothesPosition: "23px",
      avatar: {
        selectedHair: {},
        skinTone: "#FFB176",
        selectedExpression: { style: "facial_expression_3", color: "#FFB176" },
        selectedFacialHair: {},
        selectedTopClothes: "clothe_13",
        selectedBottomClothes: { style: "type_1" },
        selectedShoes: { style: "type_1" },
      },
    };
  },

  mounted() {
    this.getMembersAvatars();
  },

  methods: {
    next() {
      this.$refs.carousel.next();
    },
    prev() {
      this.$refs.carousel.prev();
    },
    async getMembersAvatars() {
      const { data, res } = await ProfileService.get_all_avatars();
      this.participant = data.data.flat();
      this.listNames = this.participant.map((user) => {
        return user.user_id;
      });
    },

    findAvatarByUser(user) {
      return this.participant.find((avatar) => avatar.user_id === user)
        .avatar_data;
    },

    userIsInAvatarList(user) {
      return this.listNames.includes(user);
    },
    getNameById(user) {
      return (
        this.orgMembersList.find((member) => member._id === user).firstname +
        " " +
        this.orgMembersList.find((member) => member._id === user).lastname
      );
    },
  },
};
</script>

<style scoped>
.nav_btn {
  border-color: antiquewhite;
  padding: 5px;
}
.carousel_nav {
  display: flex;
  justify-content: space-between;
}
.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}

.avatar_ribbon {
  height: 200px;
  margin-bottom: 5px;
}
</style>
