<template>
  <div class="scope-container">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div
        style="
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 20px;
        "
      >
        <label
          class="filter_btn"
          :class="{ selected: selectedFilter === 'users' }"
          @click="filterByUsers"
          >{{ $t("Users") }}</label
        >
        <label
          class="filter_btn"
          :class="{ selected: selectedFilter === 'levels' }"
          @click="filterByLevels"
          >{{ $t("Levels") }}</label
        >
        <label
          class="filter_btn"
          :class="{ selected: selectedFilter === 'roles' }"
          @click="filterByRoles"
          >{{ $t("Roles") }}</label
        >
      </div>
      <el-checkbox
        v-model="selectAll"
        :label="$t('Select All')"
        size="large"
        data-testid="selectAll"
      />
    </div>
    <div>
      <el-tree-select
        data-testid="users-tree-select"
        v-if="selectedFilter === 'users'"
        filterable
        style="width: 100%"
        v-model="selectedUsers"
        :data="globalStore.scopeUsersOptions"
        multiple
        :render-after-expand="false"
        show-checkbox
        check-strictly
        check-on-click-node
        class="scopeSelection"
        :placeholder="$t('Select Users')"
        :disabled="
          selectAll ||
          (preSelectedUsers &&
            preSelectedUsers.length > 0 &&
            isSwitchOn === 'no')
        "
      />

      <el-tree-select
        v-else-if="selectedFilter === 'levels'"
        filterable
        style="width: 100%"
        v-model="selectedLevels"
        :data="globalStore.scopeLevelsOptions"
        multiple
        :render-after-expand="false"
        show-checkbox
        check-strictly
        check-on-click-node
        :placeholder="$t('Select Levels')"
        :disabled="
          selectAll ||
          (preSelectedUsers &&
            preSelectedUsers.length > 0 &&
            isSwitchOn === 'no')
        "
      />
      <el-tree-select
        v-else-if="selectedFilter === 'roles'"
        filterable
        style="width: 100%"
        v-model="selectedRoles"
        :data="globalStore.scopeRolesOptions"
        multiple
        :render-after-expand="false"
        show-checkbox
        check-strictly
        check-on-click-node
        :placeholder="$t('Select Role')"
        :disabled="
          selectAll ||
          (preSelectedUsers &&
            preSelectedUsers.length > 0 &&
            isSwitchOn === 'no')
        "
      />
    </div>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/store";
export default {
  data() {
    return {
      globalStore: useGlobalStore(),
      selectedFilter: "users",
      selectAll: false,
      usersOptions: [],
      selectedUsers: [],
      levelsOptions: [],
      selectedLevels: [],
      rolesOptions: [],
      selectedRoles: [],
    };
  },
  props: {
    preSelectedUsers: Array,
    show: Boolean,
    isSwitchOn: String,
    isEdit: Boolean,
  },
  methods: {
    filterByUsers() {
      this.selectedFilter = "users";
    },
    filterByLevels() {
      this.selectedFilter = "levels";
    },
    filterByRoles() {
      this.selectedFilter = "roles";
    },
    formatPreSelectedUsers(users) {
      // Check if the first element is an object or a string (ID)
      if (users.length > 0 && typeof users[0] === "object") {
        return users.map(
          (user) =>
            `${user.email} - ${user.level_name} - ${user.job_description}`
        );
      }
      // Return as is for array of IDs
      return users;
    },
  },
  mounted() {
    this.selectedUsers = this.formatPreSelectedUsers(this.preSelectedUsers);
  },
  watch: {
    show(val) {
      if (!val) {
        this.selectedFilter = "users";
        this.selectedUsers = [];
        this.selectedLevels = [];
        this.selectedRoles = [];
        this.selectAll = false;
      }
      if (val && this.isEdit) {
        this.selectedUsers = this.formatPreSelectedUsers(this.preSelectedUsers);
      }
    },
    selectedUsers() {
      this.$emit("usersChanged", this.selectedUsers);
    },
    selectedLevels() {
      this.$emit("levelsChanged", this.selectedLevels);
    },
    selectedRoles() {
      this.$emit("rolesChanged", this.selectedRoles);
    },
    preSelectedUsers(newVal) {
      this.selectedUsers = this.formatPreSelectedUsers(newVal);
    },
    selectAll() {
      this.$emit("selectAll", this.selectAll);
    },
  },
};
</script>

<style scoped>
.scope-container {
  margin-top: 10px;
  width: 100%;
  background: #f7fafe;
  border: 1px solid #9ec2f1;
  padding: 20px;
  margin-bottom: 10px;
}
.filter_btn {
  cursor: pointer;
  font-size: 14px;
}
.filter_btn:hover {
  color: #0071ce;
}
.filter_btn.selected {
  color: #0071ce;
  font-weight: bold;
  letter-spacing: -0.02em; /* Adjust based on your font and preference */
  border-bottom: 2px solid #0071ce;
}

::v-deep .el-select__wrapper {
  box-shadow: none !important;
  border: 1px solid #c3c3c3;
  border-radius: 0 !important;
}
</style>
